.report {
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.hide {
  pointer-events: none;
  opacity: 0.3;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
}
@media print {
  .report {
    display: contents;
  }
  .dropdown {
    display: none;
  }
}
