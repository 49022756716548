.dropdown-toggle {
  background-color: #150e73 !important;
  border: #150e73;
  margin-bottom: 10px;
}

.dropdown-item.active {
  background-color: #150e73 !important;
}

.dropdown-item:active {
  background-color: #150e73 !important;
}

.dropdown-menu.show {
  overflow-y: auto;
  max-height: 80vh;
}

