table {
  border: 2px solid black !important;
}
th {
  background-color: #150e73 !important;
  text-align: center;
  color: white;
  border: 2px solid black !important;
}
tr {
  border: 2px solid black !important;
}
td {
  border: 2px solid black !important;
}
